import React from "react";
import Layout from "../components/Layouts";
import { Helmet } from "react-helmet";
import { backgroundImgServices } from "../image-imports";
import Maple from "../assets/img/maplewood.jpeg";

function Squash() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dribble</title>
        <meta name="description" content=" " />
        <meta name="keywords" content=" " />
      </Helmet>
      <div
        className="contact-Banner bg-cover shadow bg-gray"
        style={{
          backgroundImage: `url(${backgroundImgServices})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {/* <h1>AthleticTrack</h1> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h5>Build Your Sport</h5>
              <h2>Squash</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6" style={{ padding: "20px" }}>
                <h1>Maple Wood</h1>
                <p>
                  At Dribble we use only the highest quality, WSF certified,
                  maple wood flooring designed to seamlessly fit your chosen
                  area. All the products we use provide the user with
                  exceptional shock absorption, ball response and resilience and
                  includes the perfect surface tension in order to cope with the
                  world’s fastest paced sport.
                </p>
                <p>
                  Additionally we provide a constant light color throughout the
                  court so that the user can easily sight the ball. We also have
                  the skilled, expertise in order to construct your squash court
                  from the ground, up, including supplying all necessary
                  equipment like rackets, balls.
                </p>
                <a
                  data-animation="animated fadeInUp"
                  className="btn btn-sm btn-gradient"
                  href="/services/squash/maplewood"
                >
                  Discover More
                </a>
              </div>
              <div className="col-md-6" style={{ padding: "20px" }}>
                <img src={Maple} alt="Maple" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Squash;
