import React from "react";
import { facilities, fe1, fe2, fe3, fe4 } from "../image-imports";

function Facilities() {
  return (
    <div className="facilities-area default-padding">
      <div className="container">
        <div className="facilities-box">
          <div className="row">
            <div
              className="col-lg-6 thumb"
              style={{
                backgroundImage: `url(${facilities})`,
                backgroundColor: "lightgray",
              }}
            ></div>
            <div className="col-lg-6 offset-lg-6 info">
              <div className="content-box">
                <div className="heading">
                  <h5>SERVICES</h5>
                  <h2>FEATURED SERVICES</h2>
                </div>
                <div className="facilities-items">
                  <div className="item">
                    <div className="icon">
                      <img src={fe2} alt="Thumb" />
                    </div>
                    <div className="info">
                      <h4>DESIGN</h4>
                      <p>
                        Dribble seeks to build environments that nurture and
                        enrich the daily lives of individuals, communities and
                        institutions.
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="icon">
                      <img src={fe3} alt="Thumb" />
                    </div>
                    <div className="info">
                      <h4>CONSULTING</h4>
                      <p>
                        Dribble Inc helps you identify the right amount of
                        space, define the right sporting activities and sync
                        these aspects together.
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="icon">
                      <img src={fe1} alt="Thumb" />
                    </div>
                    <div className="info">
                      <h4>CONSTRUCTION</h4>
                      <p>
                        With the introduction of the RERA Act and the nationwide
                        focus on fitness and well being, there is an increasing
                        focus on providing sporting infrastructure that is
                        delivered at a higher quality, faster and at less cost.
                      </p>
                    </div>
                  </div>
                  <div className="item">
                    <div className="icon">
                      <img src={fe4} alt="Thumb" />
                    </div>
                    <div className="info">
                      <h4>OPERATIONS</h4>

                      <p>
                        Dribble Inc’s expertise in operation and management
                        arises from its experience in managing day-to-day
                        functioning at multiple sporting facilities. From
                        handling your social media, daily accounting to facility
                        maintenance and marketing, we aim to provide a one stop
                        solution while ensuring transparency in all our
                        dealings.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Facilities;
