import React from "react";
import Layout from "../components/Layouts";
import { Helmet } from "react-helmet";
import { backgroundImgServices } from "../image-imports";
import EPDM from "../assets/img/EPDM-red.jpg";
import PU from "../assets/img/PU-green.jpg";

function AthleticTrack() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dribble</title>
        <meta name="description" content=" " />
        <meta name="keywords" content=" " />
      </Helmet>
      <div
        className="contact-Banner bg-cover shadow bg-gray"
        style={{
          backgroundImage: `url(${backgroundImgServices})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {/* <h1>AthleticTrack</h1> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h5>Build Your Sport</h5>
              <h2>Athletic Track</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6" style={{ padding: "20px" }}>
                <h1>EPDM</h1>
                <p>
                  EPDM is an ecologically friendly, soft rubber flooring
                  solution. It is constructed using a layer of chemically
                  washed, cryogenized rubber filler (SBR) topped with EPDM
                  granules so that it forms a durable, water permeable base
                  which is great for any outdoor sporting activity.
                </p>
                <p>
                  Due to its sturdy yet soft nature, the surface offers maximum
                  protection to its users, which in turn minimizes the risk of
                  injury. EPDM is typically used for athletic tracks,
                  multipurpose courts and children’s play areas . The product
                  can be customized as per the client requirement with regards
                  to the layer thickness and color schemes.
                </p>
                <a
                  data-animation="animated fadeInUp"
                  className="btn btn-md btn-gradient"
                  href="/services/athletic-track/epdm"
                >
                  Discover More
                </a>
              </div>
              <div className="col-md-6" style={{ padding: "20px" }}>
                <img src={EPDM} alt="EPDM" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6" style={{ padding: "20px" }}>
                <img src={PU} alt="EPDM" />
              </div>
              <div className="col-md-6" style={{ padding: "20px" }}>
                <h1>PU - POLYURETHANE</h1>
                <p>
                  PU is a self-leveling, synthetically manufactured polymer
                  surface designed for both indoor and outdoor use, that is
                  durable, easily maintained and is ideal for heavy traffic
                  areas. It has a seamless finish and the no of layers can be
                  varied to suit client requirements.
                </p>
                <p>
                  The surface can easily stand up to the weight of heavy
                  equipment, rolling loads and high-point loads. It is ideally
                  utilized at premium multi-purpose areas and facilities.
                  Additionally, it is highly customizable with multiple color
                  options. Furthermore there is next to no maintenance costs and
                  the top layer can easily be resurfaced.
                </p>
                <a
                  data-animation="animated fadeInUp"
                  className="btn btn-md btn-gradient"
                  href="/services/athletic-track/pu"
                >
                  Discover More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default AthleticTrack;
