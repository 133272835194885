import React from "react";
import Layout from "../components/Layouts";
import { Helmet } from "react-helmet";
import { backgroundImgServices } from "../image-imports";

import Artificial from "../assets/img/ArtificialTurf.jpeg";
import synthetic from "../assets/img/Synthetic-Acrylic-full.jpg";
import PU from "../assets/img/PU-green.jpg";

function Football() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dribble</title>
        <meta name="description" content=" " />
        <meta name="keywords" content=" " />
      </Helmet>
      <div
        className="contact-Banner bg-cover shadow bg-gray"
        style={{
          backgroundImage: `url(${backgroundImgServices})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {/* <h1>AthleticTrack</h1> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h5>Build Your Sport</h5>
              <h2>Football</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6" style={{ padding: "20px" }}>
                <h1>Artificial Turf</h1>
                <p>
                  At Dribble we provide only the highest quality artificial turf
                  materials approved by FIFA. We can supply anything from basic
                  landscaping turf to the highest quality federation approved
                  turf for Football, hockey or any multipurpose facility.
                </p>
                <p>
                  The pile height (Blade height) varies based on the sport,
                  ranging from 12mm for hockey to 60mm for Football. Artificial
                  turf infill, which is required to keep the grass blades
                  vertical, varies from a combination of rubber and silica to
                  coiled grass infill depending on your choice of artificial
                  turf.
                </p>
                <a
                  data-animation="animated fadeInUp"
                  className="btn btn-sm btn-gradient"
                  href="/services/football/artificial-turf"
                >
                  Discover More
                </a>
              </div>
              <div className="col-md-6" style={{ padding: "20px" }}>
                <img src={Artificial} alt="Artificial" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6" style={{ padding: "20px" }}>
                <img src={synthetic} alt="synthetic" />
              </div>
              <div className="col-md-6" style={{ padding: "20px" }}>
                <h1>Synthetic Acrylic</h1>
                <p>
                  Synthetic Acrylic is a cost-effective, quality sports flooring
                  system for indoor and outdoor use. It can be applied as a
                  layer system and is primarily developed for multipurpose
                  courts, basketball, badminton, futsal and volleyball courts
                  and skating areas.
                </p>
                <p>
                  It leaves customers with an uniform and shock absorbent
                  surface, making it comfortable and safe for athletes.
                </p>
                <a
                  data-animation="animated fadeInUp"
                  className="btn btn-sm btn-gradient"
                  href="/services/football/synthetic-turf"
                >
                  Discover More
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6" style={{ padding: "20px" }}>
                <h1>PU</h1>
                <p>
                  PU is a self-leveling, synthetically manufactured polymer
                  surface designed for both indoor and outdoor use, that is
                  durable, easily maintained and is ideal for heavy traffic
                  areas. It has a seamless finish and the no of layers can be
                  varied to suit client requirements.
                </p>
                <p>
                  The surface can easily stand up to the weight of heavy
                  equipment, rolling loads and high-point loads. It is ideally
                  utilized at premium multi-purpose areas and facilities.
                  Additionally, it is highly customizable with multiple color
                  options. Furthermore there is next to no maintenance costs and
                  the top layer can easily be resurfaced.
                </p>
                <a
                  data-animation="animated fadeInUp"
                  className="btn btn-sm btn-gradient"
                  href="/services/football/pu"
                >
                  Discover More
                </a>
              </div>
              <div className="col-md-6" style={{ padding: "20px" }}>
                <img src={PU} alt="PU" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Football;
