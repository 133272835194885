import React from "react";
import Layout from "../components/Layouts";
import Banner from "../components/Banner";
import Facilities from "../components/Facilities";
import Categories from "../components/Categories";
import Scholarship from "../components/Scholarship";
import FunFactor from "../components/FunFactor";
import Clients from "../components/clients";
import VideoArea from "../components/VideoArea";
import { Helmet } from "react-helmet";

export default function Home() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dribble</title>
        <meta name="description" content=" " />
        <meta name="keywords" content=" " />
      </Helmet>
      <Banner />
      <Facilities />
      <Categories />
      <Scholarship />
      <FunFactor />
      {/* <Testimonials /> */}
      <VideoArea />
      <Clients />
    </Layout>
  );
}
