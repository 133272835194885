import React from "react";
import {
  AthleticTrack,
  Basketball,
  Badminton,
  Cricket,
  Football,
  Hockey,
  Multipurpose,
  Squash,
  Tennis,
} from "../image-imports";

function Categories() {
  return (
    <div className="categories-area default-padding-bottom bottom-less">
      <div className="container">
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h5>Services</h5>
              <h2>BUILD YOUR SPORT</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p className="aboutP">
              Dribble Inc seeks to build environments that nurture and enrich
              the daily lives of individuals, communities and institutions. Our
              in-house architectural team envisions sporting structures that
              thoroughly reflect our client’s aspirations and actively engage
              their users. Dribble Inc uses detailed knowledge of material
              technologies, building systems and development strategies to
              provide clients with a holistic solution for their every need.
            </p>
          </div>
        </div>
      </div>

      <div className="container" style={{ paddingTop: "80px" }}>
        <div className="category-box text-light">
          <div className="row">
            {/* <!-- Single Item --> */}
            <div className="single-item col-lg-3 col-md-6">
              <div
                className="item"
                style={{ backgroundImage: `url(${AthleticTrack})` }}
              >
                <a href="services/athletic-track">
                  <span></span>

                  <div className="title">
                    {/* <i className="flaticon-innovation"></i> */}
                    <h4>Athletic Track</h4>
                  </div>
                </a>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="single-item col-lg-3 col-md-6">
              <div
                className="item"
                style={{ backgroundImage: `url(${Basketball})` }}
              >
                <a href="services/basketball">
                  <span></span>
                  <div className="title">
                    {/* <i className="flaticon-meeting"></i> */}
                    <h4>Basketball</h4>
                  </div>
                </a>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="single-item col-lg-3 col-md-6">
              <div
                className="item"
                style={{ backgroundImage: `url(${Badminton})` }}
              >
                <a href="services/badminton">
                  <span></span>
                  <div className="title">
                    {/* <i className="flaticon-engineer-1"></i> */}
                    <h4>Badminton</h4>
                  </div>
                </a>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="single-item col-lg-3 col-md-6">
              <div
                className="item"
                style={{ backgroundImage: `url(${Cricket})` }}
              >
                <a href="services/cricket">
                  <span></span>
                  <div className="title">
                    {/* <i className="flaticon-science"></i> */}
                    <h4>Cricket</h4>
                  </div>
                </a>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="single-item col-lg-3 col-md-6">
              <div
                className="item"
                style={{ backgroundImage: `url(${Football})` }}
              >
                <a href="services/football">
                  <span></span>
                  <div className="title">
                    {/* <i className="flaticon-laptop"></i> */}
                    <h4>Football</h4>
                  </div>
                </a>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="single-item col-lg-3 col-md-6">
              <div
                className="item"
                style={{ backgroundImage: `url(${Hockey})` }}
              >
                <a href="services/hockey">
                  <span></span>
                  <div className="title">
                    {/* <i className="flaticon-book-1"></i> */}
                    <h4>Hockey</h4>
                  </div>
                </a>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="single-item col-lg-3 col-md-6">
              <div
                className="item"
                style={{ backgroundImage: `url(${Multipurpose})` }}
              >
                <a href="services/multipurpose">
                  <span></span>
                  <div className="title">
                    {/* <i className="flaticon-book-1"></i> */}
                    <h4>Multipurpose</h4>
                  </div>
                </a>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="single-item col-lg-3 col-md-6">
              <div
                className="item"
                style={{ backgroundImage: `url(${Squash})` }}
              >
                <a href="services/squash">
                  <span></span>
                  <div className="title">
                    {/* <i className="flaticon-book-1"></i> */}
                    <h4>Squash</h4>
                  </div>
                </a>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="single-item col-lg-3 col-md-6">
              <div
                className="item"
                style={{ backgroundImage: `url(${Tennis})` }}
              >
                <a href="services/tennis">
                  <span></span>
                  <div className="title">
                    {/* <i className="flaticon-book-1"></i> */}
                    <h4>Tennis</h4>
                  </div>
                </a>
              </div>
            </div>
            {/* <!-- End Single Item --> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Categories;
