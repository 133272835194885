import React from "react";
import Layout from "../components/Layouts";
import ServicesArea from "../components/Services";
import { Helmet } from "react-helmet";
import { services } from "../image-imports";

function Services() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dribble</title>
        <meta name="description" content=" " />
        <meta name="keywords" content="" />
      </Helmet>
      <div
        className="contact-Banner bg-cover shadow bg-gray"
        style={{
          backgroundImage: `url(${services})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>Services</h1>
            </div>
          </div>
        </div>
      </div>
      <ServicesArea />
    </Layout>
  );
}

export default Services;
