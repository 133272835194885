import React from "react";
import {
  c1,
  c2,
  c3,
  c4,
  c5,
  c6,
  c7,
  c8,
  c9,
  c10,
  c11,
  c12,
  c13,
  c14,
} from "../image-imports";
function clients() {
  return (
    <div className="advisor-area carousel-shadow default-padding">
      <div className="container">
        <div className="heading-left">
          <div className="row">
            <div className="col-lg-5">
              <h5>Clientele</h5>
              {/* <h2>Our Happy Clients</h2> */}
            </div>
          </div>
        </div>
      </div>

      <div className="container">
        <div className="advisor-items text-center">
          <div className="advisor-carousel owl-carousel owl-theme">
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <img src={c1} alt="cultfit" className="clientimg" />
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <img src={c2} alt="cultfit" className="clientimg" />
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <img src={c3} alt="cultfit" className="clientimg" />
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <img src={c4} alt="cultfit" className="clientimg" />
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <img src={c5} alt="cultfit" className="clientimg" />
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <img src={c6} alt="cultfit" className="clientimg" />
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <img src={c7} alt="cultfit" className="clientimg" />
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <img src={c8} alt="cultfit" className="clientimg" />
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <img src={c9} alt="cultfit" className="clientimg" />
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <img src={c10} alt="cultfit" className="clientimg" />
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <img src={c11} alt="cultfit" className="clientimg" />
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <img src={c12} alt="cultfit" className="clientimg" />
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <img src={c13} alt="cultfit" className="clientimg" />
              </div>
            </div>
            {/* <!-- End Single Item --> */}
            {/* <!-- Single Item --> */}
            <div className="item">
              <div className="info">
                <img src={c14} alt="cultfit" className="clientimg" />
              </div>
            </div>
            {/* <!-- End Single Item --> */}
          </div>
        </div>
      </div>
    </div>
  );
}

export default clients;
