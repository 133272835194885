import React from "react";
import { drblVideo } from "../image-imports";
import Videoplayer from "../components/videoPlayer";

function VideoArea() {
  return (
    <>
      <div
        className="video-area padding-xl text-center bg-fixed text-light shadow dark-hard"
        style={{ backgroundImage: `url(${drblVideo})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="video-heading">
                <div className="content">
                  <h2>Take a short tour on our Designs</h2>
                  <p>
                    Dribble Inc’s expertise in operation and management arises
                    from its experience in managing day-to-day functioning at
                    multiple sporting facilities. From handling your social
                    media, daily accounting to facility maintenance and
                    marketing, we aim to provide a one stop solution while
                    ensuring transparency in all our dealings.
                  </p>
                </div>
                {/* <a
                  className="popup-youtube relative video-play-button"
                  href="https://www.youtube.com/watch?v=8GQTt50izkg"
                >
                  <i className="fa fa-play"></i>
                </a> */}

                <Videoplayer />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoArea;
