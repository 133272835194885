import React from "react";
import { dribbleContact } from "../image-imports";

function Scholarship() {
  return (
    <div className="scholarship-area bg-gray default-padding">
      <div className="container">
        <div className="item-box">
          <div className="row align-center">
            <div className="col-lg-6 thumb">
              <div className="thumb-box">
                <img src={dribbleContact} alt="Thumb" />
              </div>
            </div>
            <div className="col-lg-6 info">
              <h2>Get Free Quote.</h2>
              <p className="text-justify">
                Dribble’s expertise in operation and management arises from its
                experience in managing day-to-day functioning at multiple
                sporting facilities. From handling your social media, daily
                accounting to facility maintenance and marketing, we aim to
                provide a one stop solution while ensuring transparency in all
                our dealings.
              </p>
              {/* <ul>
                <li>
                  <div className="content">
                    <h4>Nationality</h4>
                    <p>
                      Discourse unwilling am no described dejection incommode no
                      listening of. Before nature his parish boy.
                    </p>
                  </div>
                </li>
                <li>
                  <div className="content">
                    <h4>Level of study</h4>
                    <p>
                      Discourse unwilling am no described dejection incommode no
                      listening of. Before nature his parish boy.
                    </p>
                  </div>
                </li>
              </ul> */}
              <a className="btn btn-md btn-gradient" href="/contact">
                Get a Free Quote
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Scholarship;
