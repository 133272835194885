import React from "react";

import Categories from "../components/Categories";

function ServicesArea() {
  return (
    <>
      <div className="categories-area default-padding-bottom bottom-less about-padding-top">
        <Categories />
      </div>
    </>
  );
}

export default ServicesArea;
