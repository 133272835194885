import React from "react";
import Header from "../Header";
import Footer from "../Footer";

function Layout({ children, mode, ...props }) {
  return (
    <React.Fragment>
      {/* <TopHeader /> */}
      <Header />
      {children}
      <Footer mode={mode} />
    </React.Fragment>
  );
}

export default Layout;
