import React from "react";
import Artificial from "../assets/img/ArtificialTurf.jpeg";
import Landscape from "../assets/img/landscape-artificial_turf.jpeg";
import Football from "../assets/img/football-artificial_turf.jpeg";
import Multisport from "../assets/img/multipurpose-artificial_turf.jpeg";

function epdm() {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <img src={Artificial} alt="Artificial Turf" />
          </div>
          <div className="col-md-12" style={{ padding: "20px" }}>
            <h1>Artificial Turf</h1>
            <p>
              At Dribble we provide only the highest quality artificial turf
              materials approved by FIFA. We can supply anything from basic
              landscaping turf to the highest quality federation approved turf
              for Football, hockey or any multipurpose facility.
            </p>
            <p>
              The pile height (Blade height) varies based on the sport, ranging
              from 12mm for hockey to 60mm for Football. Artificial turf infill,
              which is required to keep the grass blades vertical, varies from a
              combination of rubber and silica to coiled grass infill depending
              on your choice of artificial turf.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <div className="row">
              <div className="col-md-12">
                <h1>COLOUR & OPTIONS</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" style={{ padding: "20px" }}>
                <h3>Landscape Artificial Turf</h3>
                <img src={Landscape} alt="Landscape Turf" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" style={{ padding: "20px" }}>
                <h3>Football Artificial Turf</h3>
                <img src={Football} alt="Football Turf" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" style={{ padding: "20px" }}>
                <h3>Multisport Artificial Turf</h3>
                <img src={Multisport} alt="Multisport Turf" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <p>
                  We offer a wide range of colour and size options among other
                  things so the client can go here to choose his/her preference
                  and size to get the ultimate customization.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <h1>ADVANTAGES</h1>
            <ul>
              <li>Looks and feels like natural grass and weather resistant</li>
              <li>
                Extremely durable with federation certifications as per sport
              </li>
              <li>Very low maintenance, no water required</li>
              <li>Huge variety of turf options available</li>
              <li>
                Turnkey installation including base work, lighting, rebound
                systems.
              </li>
              <li>
                Cost varies based on blade height, grass density and backing
                material
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default epdm;
