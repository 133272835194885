import React from "react";
import SyntheticAcrylicI from "../assets/img/Synthetic-Acrylic-full.jpg";

function SyntheticAcrylic() {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <img src={SyntheticAcrylicI} alt="Synthetic Acrylic" />
          </div>
          <div className="col-md-12" style={{ padding: "20px" }}>
            <h1>Synthetic Acrylic</h1>
            <p>
              Synthetic Acrylic is a cost-effective, quality sports flooring
              system for indoor and outdoor use. It can be applied as a layer
              system and is primarily developed for multipurpose courts,
              basketball, badminton, futsal and volleyball courts and skating
              areas.
            </p>
            <p>
              It leaves customers with an uniform and shock absorbent surface,
              making it comfortable and safe for athletes.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <div className="row">
              <div className="col-md-12">
                <h1>COLOUR & OPTIONS</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#F5F5DC",
                        height: "150px",
                        color: "#000000",
                        border: "0px",
                      }}
                    >
                      Beige
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#FFFF00",
                        height: "150px",
                        color: "#000000",
                        border: "none",
                      }}
                    >
                      {" "}
                      Yellow
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#FFA500",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Orange
                    </div>
                  </div>

                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#E56E94",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Blush red
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#880808",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Blood red
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#800000",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Maroon
                    </div>
                  </div>
                </div>
                {/* end of row */}
                <div className="row">
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#E2725B",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Terracotta
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#964B00",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      {" "}
                      Brown
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#32CD32",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Lime Green
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#228B22",
                        height: "150px",
                        color: "#000000",
                      }}
                    >
                      Forest Green
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#4F7942",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Fern green
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#2E8B57",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Sea green
                    </div>
                  </div>
                </div>
                {/* end of row */}
                <div className="row">
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#87CEEB",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Sky blue
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#C2DFFF",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      {" "}
                      Sea Blue
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#708090",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Slate grey
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#800080",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Purple
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#808080",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Grey
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#5E5042",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Cedar brown
                    </div>
                  </div>
                </div>
                {/* end of row */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p>
                  We offer a wide range of colour and size options among other
                  things so the client can go here to choose his/her preference
                  and size to get the ultimate customization.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <h1>ADVANTAGES</h1>
            <ul>
              <li>Good bounce and anti-slip properties</li>
              <li>Can be used for both indoor and outdoor use</li>
              <li>Highly customizable – available in multiple color options</li>
              <li>Different number of layer options – 5,7 or 9 level system</li>
              <li>Base preparation can be customized for purpose.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SyntheticAcrylic;
