import React from "react";
import Home from "./pages/home";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Contact from "./pages/contact";
import Services from "./pages/services";
import AboutUs from "./pages/aboutUs";
import AthleticTrack from "./pages/AthleticTrack";
import Basketball from "./pages/Basketball";
import Badminton from "./pages/Badminton";
import Cricket from "./pages/Cricket";
import Football from "./pages/Football";
import Hockey from "./pages/Hockey";
import Multipurpose from "./pages/Multipurpose";
import Squash from "./pages/Squash";
import Tennis from "./pages/Tennis";
import ATEPDM from "./pages/ATEpdm";
import ATPU from "./pages/ATPu";
import BadmintonEPDM from "./pages/BadmintonEpdm";
import BadmintonArtificial from "./pages/BadmintonArtificial";
import BadmintonSynthetic from "./pages/BadmintonSynthetic";
import BadmintonPu from "./pages/BadmintonPu";
import BadmintonVinyl from "./pages/BadmintonVinyl";
import Multiepdm from "./pages/multiEpdm";
import Multiartificial from "./pages/MultipurposeArtificial";
import Multipu from "./pages/Multipurposepu";
import Multivinyl from "./pages/MultipurposeVinyl";
import BasketballVinyl from "./pages/basketballVinyl";
import BasketballPu from "./pages/basketballPU";
import BasketballSynthetic from "./pages/BasketballSyntheticAcrylic";
import FootballArtificial from "./pages/footballartificial";
import FootballSynthetic from "./pages/footballsynthetic";
import FootballPu from "./pages/footballpu";
import Cricketartificial from "./pages/CricketArtificial";
import Hockeyartificial from "./pages/HockeyArtificial";
import SquashMaplewood from "./pages/SquashMaple";
import TennisAT from "./pages/TennisArtificial";
import TennisSA from "./pages/tennisSynthetic";
import TennisPu from "./pages/Tennispu";
import ScrollToTop from "./components/scrollToTop";

function App() {
  return (
    <Router>
      <ScrollToTop />
      {/* <React.Fragment>
      <Home />
    </React.Fragment> */}
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/services" element={<Services />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/services/athletic-track" element={<AthleticTrack />} />
        <Route path="services/basketball" element={<Basketball />} />
        <Route path="services/badminton" element={<Badminton />} />
        <Route path="services/cricket" element={<Cricket />} />
        <Route path="services/football" element={<Football />} />
        <Route path="services/hockey" element={<Hockey />} />
        <Route path="services/multipurpose" element={<Multipurpose />} />
        <Route path="services/squash" element={<Squash />} />
        <Route path="services/tennis" element={<Tennis />} />
        <Route path="services/athletic-track/epdm" element={<ATEPDM />} />
        <Route path="services/athletic-track/pu" element={<ATPU />} />
        <Route path="/services/badminton/epdm" element={<BadmintonEPDM />} />
        <Route
          path="/services/badminton/artificial-turf"
          element={<BadmintonArtificial />}
        />
        <Route
          path="/services/badminton/synthetic-acrylic"
          element={<BadmintonSynthetic />}
        />
        <Route path="/services/badminton/pu" element={<BadmintonPu />} />
        <Route path="/services/badminton/vinyl" element={<BadmintonVinyl />} />

        <Route path="/services/multipurpose/epdm" element={<Multiepdm />} />
        <Route
          path="/services/multipurpose/Artificial"
          element={<Multiartificial />}
        />
        <Route path="/services/multipurpose/pu" element={<Multipu />} />
        <Route path="/services/multipurpose/Vinyl" element={<Multivinyl />} />
        <Route
          path="/services/basketball/Vinyl"
          element={<BasketballVinyl />}
        />

        <Route path="/services/basketball/pu" element={<BasketballPu />} />
        <Route
          path="/services/basketball/Synthetic-Acrylic"
          element={<BasketballSynthetic />}
        />
        <Route
          path="/services/football/artificial-turf"
          element={<FootballArtificial />}
        />
        <Route
          path="/services/football/synthetic-turf"
          element={<FootballSynthetic />}
        />
        <Route path="/services/football/pu" element={<FootballPu />} />
        <Route
          path="/services/cricket/Artificial-Turf"
          element={<Cricketartificial />}
        />
        <Route
          path="/services/Hockey/Artificial-Turf"
          element={<Hockeyartificial />}
        />
        <Route
          path="/services/squash/maplewood"
          element={<SquashMaplewood />}
        />
        <Route path="/services/Tennis/Artificial-Turf" element={<TennisAT />} />
        <Route
          path="/services/Tennis/Synthetic-Acrylic"
          element={<TennisSA />}
        />
        <Route path="/services/Tennis/pu" element={<TennisPu />} />
      </Routes>
    </Router>
  );
}

export default App;
