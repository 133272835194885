import React from "react";
import EPDM from "../assets/img/EPDM-red.jpg";

function epdm() {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <img src={EPDM} alt="EPDM" />
          </div>
          <div className="col-md-12" style={{ padding: "20px" }}>
            <h1>EPDM</h1>
            <p>
              EPDM is an ecologically friendly, soft rubber flooring solution.
              It is constructed using a layer of chemically washed, cryogenized
              rubber filler (SBR) topped with EPDM granules so that it forms a
              durable, water permeable base which is great for any outdoor
              sporting activity.
            </p>
            <p>
              Due to its sturdy yet soft nature, the surface offers maximum
              protection to its users, which in turn minimizes the risk of
              injury. EPDM is typically used for athletic tracks, multipurpose
              courts and children’s play areas . The product can be customized
              as per the client requirement with regards to the layer thickness
              and color schemes.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <div className="row">
              <div className="col-md-12">
                <h1>COLOUR & OPTIONS</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#00308f",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Dark blue
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#762896",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      {" "}
                      Purple
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#1c5340",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Forest Green
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#c71e23",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Blood red
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#7d797a",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Grey
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#004080",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Ocean blue
                    </div>
                  </div>
                </div>
                {/* end of row */}
                <div className="row">
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#d85372",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Pink
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#915c1a",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      {" "}
                      Brown
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#62321c",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Terracotta
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#ffffff",
                        height: "150px",
                        color: "#000000",
                      }}
                    >
                      White
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#326aa5",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      light blue
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#238015",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Lime Green
                    </div>
                  </div>
                </div>
                {/* end of row */}
                <div className="row">
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#dd7834",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Orange
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#eac133",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      {" "}
                      Yellow
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#000000",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Black
                    </div>
                  </div>
                </div>
                {/* end of row */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p>
                  We offer a wide range of colour and size options among other
                  things so the client can go here to choose his/her preference
                  and size to get the ultimate customization.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <h1>ADVANTAGES</h1>
            <ul>
              <li>
                Totally customizable product – different EPDM layer thickness
                and color options available
              </li>
              <li>Easy maintenance due to the water permeable surface</li>
              <li>
                Solid flooring base which absorbs high impact, reducing the risk
                of injury and dampening the stress impact on one’s body
              </li>
              <li>
                Durable alternative to Synthetic acrylic and PU, which can be
                easily repaired in case of any damage
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default epdm;
