import React from "react";
import {} from "../image-imports";

function About() {
  return (
    <>
      <div className="container" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h5>About Dribble</h5>
              <h2>We Poineer You Play</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12">
            <p className="aboutP">
              Dribble’s expertise in operation and management arises from its
              experience in managing day-to-day functioning at multiple sporting
              facilities. From handling your social media, daily accounting to
              facility maintenance and marketing, we aim to provide a one stop
              solution while ensuring transparency in all our dealings.
            </p>
          </div>
        </div>
      </div>
      {/* <div className="about-area default-padding-top about-padding-top">
        <div className="fixed-shape-bottom">
          <img src={dottedShape} alt="Thumb" />
        </div>
        <div className="container">
          <div className="about-items">
            <div className="row align-center">
              <div className="col-lg-6 info">
                <h2>Turn your ambition into a success story</h2>
                <p>
                  Education opens up the mind, expands it and allows you to
                  improve your life in so many ways. Turned it up should no
                  valley cousin he. Speaking numerous ask did horrible packages
                  set. Ashamed herself has distant can studied mrs. Led
                  therefore its middleton perpetual fulfilled provision
                  frankness it up should no valley.
                </p>
                <ul>
                  <li>
                    <div className="fun-fact">
                      <span className="timer" data-to="12" data-speed="5000">
                        12
                      </span>
                      <span className="medium">Happy Students</span>
                    </div>
                  </li>
                  <li>
                    <div className="fun-fact">
                      <span className="timer" data-to="10" data-speed="5000">
                        10
                      </span>
                      <span className="medium">Visa & Immegrations</span>
                    </div>
                  </li>
                </ul>
              </div>

              <div className="col-lg-6 thumb">
                <img src={img1202} alt="Thumb" />
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* section 2? */}
      {/* section 3 */}
      <div className="advisor-area default-padding bottom-less">
        <div className="container">
          <div className="heading-left">
            <div className="row">
              <div className="col-lg-5">
                <h5>Course Advisor</h5>
                <h2>Our professional &amp; Expert Advisor</h2>
              </div>
              <div className="col-lg-6 offset-lg-1">
                <p>
                  Dribble’s expertise in operation and management arises from
                  its experience in managing day-to-day functioning at multiple
                  sporting facilities. From handling your social media, daily
                  accounting to facility maintenance and marketing, we aim to
                  provide a one stop solution while ensuring transparency in all
                  our dealings.
                </p>
                <a className="btn btn-md btn-dark border" href="contact">
                  Get Advise <i className="fas fa-plus"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default About;
