import React from "react";
import Vinyl from "../assets/img/Vinyl.jpeg";

function epdm() {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <img src={Vinyl} alt="Vinyl" />
          </div>
          <div className="col-md-12" style={{ padding: "20px" }}>
            <h1>Vinyl</h1>
            <p>
              Vinyl’s versatility has made it one of the most used indoor
              flooring in the industry. It’s superior comfort and exceptional
              finish make it the ultimate choice for several sports such as
              indoor basketball, yoga halls, table tennis, billiards, indoor
              badminton, indoor volleyball and multipurpose indoor facilities.
            </p>
            <p>
              Our composition is a state-of-the-art, cost effective polymer
              based flooring that is designed to aesthetically complement your
              facility. We provide Sports Vinyl in both wood pattern and solid
              colour finishes.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <div className="row">
              <div className="col-md-12">
                <h1>COLOUR & OPTIONS</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#7c4526",
                        height: "150px",
                        color: "#000000",
                        border: "0px",
                      }}
                    >
                      Gunstock Oak
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#d4ab77",
                        height: "150px",
                        color: "#000000",
                        border: "none",
                      }}
                    >
                      {" "}
                      Maple
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#214266",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Royal Blue
                    </div>
                  </div>

                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#4285af",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Sky blue
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#228B22",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Forest green
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#507a52",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      field green
                    </div>
                  </div>
                </div>
                {/* end of row */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p>
                  We offer a wide range of colour and size options among other
                  things so the client can go here to choose his/her preference
                  and size to get the ultimate customization.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <h1>ADVANTAGES</h1>
            <ul>
              <li>Extremely resistant to wear and tear</li>
              <li>Easy to maintain and assemble</li>
              <li>For both indoor and outdoor use</li>
              <li>
                Available in multiple sizes and specifications in order to best
                fit your budget
              </li>
              <li>Slip resistant and enhances playing characteristics</li>
              <li>5 year warranty</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default epdm;
