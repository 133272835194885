import React from "react";
import Layout from "../components/Layouts";
import { Helmet } from "react-helmet";
import { backgroundImgServices } from "../image-imports";
import Maple from "../components/MapleWood";
import Scholarship from "../components/Scholarship";

function SquashMaple() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dribble</title>
        <meta name="description" content=" " />
        <meta name="keywords" content=" " />
      </Helmet>
      <div
        className="contact-Banner bg-cover shadow bg-gray"
        style={{
          backgroundImage: `url(${backgroundImgServices})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {/* <h1>AthleticTrack</h1> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h5>Build Your Track</h5>
              <h2>Squash - Maple Wood Turf</h2>
            </div>
          </div>
        </div>
        <Maple />
      </div>
      <Scholarship />
    </Layout>
  );
}

export default SquashMaple;
