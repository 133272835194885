import React from "react";
import PU from "../assets/img/PU-green.jpg";

function pu() {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <img src={PU} alt="PU" />
          </div>
          <div className="col-md-12" style={{ padding: "20px" }}>
            <h1>PU - Polyurethane</h1>
            <p>
              PU is a self-leveling, synthetically manufactured polymer surface
              designed for both indoor and outdoor use, that is durable, easily
              maintained and is ideal for heavy traffic areas. It has a seamless
              finish and the no of layers can be varied to suit client
              requirements.
            </p>
            <p>
              The surface can easily stand up to the weight of heavy equipment,
              rolling loads and high-point loads. It is ideally utilized at
              premium multi-purpose areas and facilities. Additionally, it is
              highly customizable with multiple color options. Furthermore there
              is next to no maintenance costs and the top layer can easily be
              resurfaced.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <div className="row">
              <div className="col-md-12">
                <h1>COLOUR & OPTIONS</h1>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#F5F5DC",
                        height: "150px",
                        color: "#000000",
                        border: "0px",
                      }}
                    >
                      Beige
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#FFFF00",
                        height: "150px",
                        color: "#000000",
                        border: "none",
                      }}
                    >
                      {" "}
                      Yellow
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#FFA500",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Orange
                    </div>
                  </div>

                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#E56E94",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Blush red
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#880808",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Blood red
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#800000",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Maroon
                    </div>
                  </div>
                </div>
                {/* end of row */}
                <div className="row">
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#E2725B",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Terracotta
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#964B00",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      {" "}
                      Brown
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#32CD32",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Lime Green
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#228B22",
                        height: "150px",
                        color: "#000000",
                      }}
                    >
                      Forest Green
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#4F7942",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Fern green
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#2E8B57",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Sea green
                    </div>
                  </div>
                </div>
                {/* end of row */}
                <div className="row">
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#87CEEB",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Sky blue
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#C2DFFF",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      {" "}
                      Sea Blue
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#708090",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Slate grey
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#800080",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Purple
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#808080",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Grey
                    </div>
                  </div>
                  <div className="col-md-2 colorbox">
                    <div
                      className="inner-colorbox"
                      style={{
                        background: "#5E5042",
                        height: "150px",
                        color: "#ffffff",
                      }}
                    >
                      Cedar brown
                    </div>
                  </div>
                </div>
                {/* end of row */}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <p>
                  We offer a wide range of colour and size options among other
                  things so the client can go here to choose his/her preference
                  and size to get the ultimate customization.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <h1>ADVANTAGES</h1>
            <ul>
              <li>
                Better durability and resistance than other surface options
              </li>
              <li>Long life span with low maintenance</li>
              <li>Multiple color options with seamless finish</li>
              <li>
                Multilayer impact system resulting in lesser impact on the user
              </li>
              <li>
                Completely safe for indoor/outdoor use and use by children
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default pu;
