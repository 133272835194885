import React from "react";
import { map } from "../image-imports";

function FunFactor() {
  return (
    <div className="fun-factor-area overflow-hidden bg-gradient text-light default-padding">
      <div className="container">
        <div className="fun-fact-items text-center">
          {/* <!-- Fixed BG --> */}
          <div
            className="fixed-bg contain"
            style={{ backgroundImage: `url(${map})` }}
          ></div>
          {/* <!-- Fixed BG --> */}
          <div className="row">
            <div className="col-lg-3 col-md-6 item">
              <div className="fun-fact">
                <div className="counter">
                  <div className="timer" data-to="9" data-speed="5000">
                    9
                  </div>
                  <div className="operator"></div>
                </div>
                <span className="medium">Sports</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 item">
              <div className="fun-fact">
                <div className="counter">
                  <div className="timer" data-to="30" data-speed="5000">
                    30
                  </div>
                  <div className="operator">+</div>
                </div>
                <span className="medium">Facilities</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 item">
              <div className="fun-fact">
                <div className="counter">
                  <div className="timer" data-to="10" data-speed="5000">
                    10
                  </div>
                  <div className="operator">+</div>
                </div>
                <span className="medium">Cities</span>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 item">
              <div className="fun-fact">
                <div className="counter">
                  <div className="timer" data-to="10" data-speed="5000">
                    10
                  </div>
                  <div className="operator">+</div>
                </div>
                <span className="medium">States</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FunFactor;
