import React from "react";
import Layout from "../components/Layouts";
import AboutArea from "../components/about";
import Clients from "../components/clients";
import { Helmet } from "react-helmet";
import { backgroundImgServices } from "../image-imports";

function Partners() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dribble</title>
        <meta name="description" content=" " />
        <meta name="keywords" content=" " />
      </Helmet>
      <div
        className="contact-Banner bg-cover shadow bg-gray"
        style={{
          backgroundImage: `url(${backgroundImgServices})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <h1>About Us</h1>
            </div>
          </div>
        </div>
      </div>
      <AboutArea />
      <div className="container-fluid" style={{ background: "#ccc" }}>
        <Clients />
      </div>
    </Layout>
  );
}

export default Partners;
