import React from "react";
import Layout from "../components/Layouts";
import ContactArea from "../components/ContactArea";
import { Helmet } from "react-helmet";
// import Location from "../components/Location";

function Contact() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Contact Us Now | Dribble.co.in</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
      </Helmet>
      <ContactArea mode="dark" />
      {/* <Location /> */}
    </Layout>
  );
}

export default Contact;
