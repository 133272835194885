import React from "react";
import { logoLight } from "../image-imports";

function Footer({ mode }) {
  return (
    <footer className={mode === "light" ? "" : "bg-dark text-light"}>
      <div className="container">
        <div className="f-items default-padding">
          <div className="row">
            <div className="col-lg-4 col-md-6 item">
              <a className="" href="/">
                <img
                  src={logoLight}
                  className=""
                  alt="Logo"
                  style={{ width: "50%" }}
                />
              </a>
              <br />
              <div className="f-item about">
                {/* <h4 className="widget-title">About</h4> */}
                <p>
                  Dribble’s expertise in operation and management arises from
                  its experience in managing day-to-day functioning at multiple
                  sporting facilities. From handling your social media, daily
                  accounting to facility maintenance and marketing, we aim to
                  provide a one stop solution while ensuring transparency in all
                  our dealings.
                </p>
                <div className="social">
                  <h5>Follow Us</h5>
                  <ul>
                    <li className="facebook">
                      <a href="/">
                        <i className="fab fa-facebook-f"></i>
                      </a>
                    </li>
                    <li className="twitter">
                      <a href="/">
                        <i className="fab fa-twitter"></i>
                      </a>
                    </li>
                    <li className="instagram">
                      <a href="/">
                        <i className="fab fa-instagram"></i>
                      </a>
                    </li>
                    <li className="linkedin">
                      <a href="/">
                        <i className="fab fa-linkedin-in"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 item">
              <div className="f-item link">
                <h4 className="widget-title">Quick Links</h4>
                <ul>
                  <li>
                    <a href="AboutUs">About Us</a>
                  </li>
                  <li>
                    <a href="services">Build Your Sport</a>
                  </li>

                  <li>
                    <a href="contact">Contact</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 item">
              <div className="f-item link">
                <h4 className="widget-title">Build Your Sport</h4>
                <ul>
                  <li>
                    <a href="services/athletic-track">ATHLETIC TRACK</a>
                  </li>
                  <li>
                    <a href="services/basketball">BASKETBALL</a>
                  </li>
                  <li>
                    <a href="services/squash">SQUASH</a>
                  </li>
                  <li>
                    <a href="services/football">FOOTBALL</a>
                  </li>
                  <li>
                    <a href="services/tennis">TENNIS</a>
                  </li>
                  <li>
                    <a href="services/hockey">HOCKEY</a>
                  </li>
                  <li>
                    <a href="services/badminton">BADMINTON</a>
                  </li>
                  <li>
                    <a href="services/cricket">CRICKET/LANDSCAPE</a>
                  </li>
                  <li>
                    <a href="services/multipurpose">MULTIPURPOSE</a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 item">
              <div className="f-item contact">
                <h4 className="widget-title">Contact Info</h4>
                <div className="address">
                  <ul>
                    <li>
                      <strong>Email:</strong> info@dribble.co.in
                    </li>
                    <li>
                      <strong>Contact:</strong> +91-8197140530
                    </li>
                  </ul>
                </div>
                <div className="opening-info">
                  <h5>Opening Hours</h5>
                  <ul>
                    <li>
                      <span> Sun - Thu :8:30 AM - 9 PM</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Footer Bottom --> */}
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <p>
                &copy; Copyright 2023. All Rights Reserved by{" "}
                <a href="/">Dribble Inc</a>
              </p>
            </div>
            <div className="col-lg-6 text-right link">
              <ul>
                <li>
                  <a href="/">Terms</a>
                </li>
                <li>
                  <a href="/">Privacy</a>
                </li>
                <li>
                  <a href="/contact">Support</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- End Footer Bottom --> */}
    </footer>
  );
}

export default Footer;
