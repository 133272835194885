import React from "react";
import Layout from "../components/Layouts";
import { Helmet } from "react-helmet";
import { backgroundImgServices } from "../image-imports";
import Vinyl from "../assets/img/Vinyl.jpeg";
import PU from "../assets/img/PU-green.jpg";
import synthetic from "../assets/img/Synthetic-Acrylic-full.jpg";

function Basketball() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dribble</title>
        <meta name="description" content=" " />
        <meta name="keywords" content=" " />
      </Helmet>
      <div
        className="contact-Banner bg-cover shadow bg-gray"
        style={{
          backgroundImage: `url(${backgroundImgServices})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {/* <h1>AthleticTrack</h1> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h5>Build Your Sport</h5>
              <h2>Basketball</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6" style={{ padding: "20px" }}>
                <h1>Synthetic Acrylic</h1>
                <p>
                  Synthetic Acrylic is a cost-effective, quality sports flooring
                  system for indoor and outdoor use. It can be applied as a
                  layer system and is primarily developed for multipurpose
                  courts, basketball, badminton, futsal and volleyball courts
                  and skating areas.
                </p>
                <p>
                  It leaves customers with an uniform and shock absorbent
                  surface, making it comfortable and safe for athletes.
                </p>
                <a
                  data-animation="animated fadeInUp"
                  className="btn btn-sm btn-gradient"
                  href="/services/basketball/Synthetic-Acrylic"
                >
                  Discover More
                </a>
              </div>
              <div className="col-md-6" style={{ padding: "20px" }}>
                <img src={synthetic} alt="synthetic" />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6" style={{ padding: "20px" }}>
                <img src={PU} alt="PU" />
              </div>
              <div className="col-md-6" style={{ padding: "20px" }}>
                <h1>PU - POLYURETHANE</h1>
                <p>
                  PU is a self-leveling, synthetically manufactured polymer
                  surface designed for both indoor and outdoor use, that is
                  durable, easily maintained and is ideal for heavy traffic
                  areas. It has a seamless finish and the no of layers can be
                  varied to suit client requirements.
                </p>
                <p>
                  The surface can easily stand up to the weight of heavy
                  equipment, rolling loads and high-point loads. It is ideally
                  utilized at premium multi-purpose areas and facilities.
                  Additionally, it is highly customizable with multiple color
                  options. Furthermore there is next to no maintenance costs and
                  the top layer can easily be resurfaced.
                </p>
                <a
                  data-animation="animated fadeInUp"
                  className="btn btn-sm btn-gradient"
                  href="/services/basketball/pu"
                >
                  Discover More
                </a>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6" style={{ padding: "20px" }}>
                <h1>Vinyl</h1>
                <p>
                  Vinyl’s versatility has made it one of the most used indoor
                  flooring in the industry. It’s superior comfort and
                  exceptional finish make it the ultimate choice for several
                  sports such as indoor basketball, yoga halls, table tennis,
                  billiards, indoor badminton, indoor volleyball and
                  multipurpose indoor facilities.
                </p>
                <p>
                  Our composition is a state-of-the-art, cost effective polymer
                  based flooring that is designed to aesthetically complement
                  your facility. We provide Sports Vinyl in both wood pattern
                  and solid colour finishes.
                </p>
                <a
                  data-animation="animated fadeInUp"
                  className="btn btn-sm btn-gradient"
                  href="/services/basketball/Vinyl"
                >
                  Discover More
                </a>
              </div>
              <div className="col-md-6" style={{ padding: "20px" }}>
                <img src={Vinyl} alt="Vinyl" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Basketball;
