import React from "react";
import { logoLight } from "../image-imports";

function Header() {
  return (
    <header id="home">
      <nav className="navbar navbar-default inc-top-bar attr-border navbar-fixed white no-background bootsnav">
        <div className="container">
          <div className="row">
            <div className="top-search">
              <div className="input-group">
                <form action="#">
                  <input
                    type="text"
                    name="text"
                    className="form-control"
                    placeholder="Search"
                  />
                  <button type="submit">
                    <i className="ti-search"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="attr-nav">
            <ul>
              <li className="search">
                <a href="/">
                  <i className="ti-search"></i>
                </a>
              </li>
            </ul>
          </div>

          <div className="navbar-header">
            <button
              type="button"
              className="navbar-toggle"
              data-toggle="collapse"
              data-target="#navbar-menu"
            >
              <i className="fa fa-bars"></i>
            </button>
            <a className="navbar-brand" href="/">
              <img src={logoLight} className="logo logo-display" alt="Logo" />
              <img src={logoLight} className="logo logo-scrolled" alt="Logo" />
            </a>
          </div>

          <div className="collapse navbar-collapse" id="navbar-menu">
            <ul
              className="nav navbar-nav navbar-right"
              data-in="fadeInDown"
              data-out="fadeOutUp"
            >
              <li>
                <a href="/">Home </a>
              </li>
              <li>
                <a href="/AboutUs">About Us</a>
              </li>
              <li>
                <a href="/services">Build Your Sport</a>
              </li>
              <li>
                <a href="/contact">Contact</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
}

export default Header;
