import React, { useState } from "react";
import Modal from "react-modal";
import video from "../assets/videos/Dribble-Design_Video.mp4";

const customStyles = {
  overlay: {
    zIndex: 1000, // Set the z-index for the overlay
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    transform: "translate(-50%, -50%)",
    zIndex: 9999,
  },
};

Modal.setAppElement("#root");

function VideoPlayer() {
  const [modalIsOpen, setModalIsOpen] = useState(false);

  function openModal() {
    setModalIsOpen(true);
  }

  function closeModal() {
    setModalIsOpen(false);
  }

  return (
    <div>
      <a onClick={openModal} className="relative video-play-button">
        <i className="fa fa-play"></i>
      </a>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Video Popup"
      >
        <button onClick={closeModal} className="close-button">
          X
        </button>
        <div>
          <video
            controls
            width="750"
            autoPlay
            autoFocus
            style={{ zIndex: "9" }}
          >
            <source src={video} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>
      </Modal>
    </div>
  );
}

export default VideoPlayer;
