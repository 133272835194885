import React from "react";
import Layout from "../components/Layouts";
import { Helmet } from "react-helmet";
import { backgroundImgServices } from "../image-imports";
import Artificial from "../assets/img/Artificial-Turf-complete.jpg";

function Cricket() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dribble</title>
        <meta name="description" content=" " />
        <meta name="keywords" content=" " />
      </Helmet>
      <div
        className="contact-Banner bg-cover shadow bg-gray"
        style={{
          backgroundImage: `url(${backgroundImgServices})`,
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              {/* <h1>AthleticTrack</h1> */}
            </div>
          </div>
        </div>
      </div>
      <div className="container" style={{ paddingTop: "60px" }}>
        <div className="row">
          <div className="col-lg-8 offset-lg-2">
            <div className="site-heading text-center">
              <h5>Build Your Sport</h5>
              <h2>Cricket</h2>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="row">
              <div className="col-md-6" style={{ padding: "20px" }}>
                <h1>Artificial Turf</h1>
                <p>
                  At Dribble we provide only the highest quality artificial turf
                  materials approved by FIFA. We can supply anything from basic
                  landscaping turf to the highest quality federation approved
                  turf for Football, hockey or any multipurpose facility.
                </p>
                <p>
                  The surface can easily stand up to the weight of heavy
                  equipment, rolling loads and high-point loads. It is ideally
                  utilized at premium multi-purpose areas and facilities.
                  Additionally, it is highly customizable with multiple color
                  options. Furthermore there is next to no maintenance costs and
                  the top layer can easily be resurfaced.
                </p>
                <a
                  data-animation="animated fadeInUp"
                  className="btn btn-sm btn-gradient"
                  href="/services/cricket/Artificial-Turf"
                >
                  Discover More
                </a>
              </div>
              <div className="col-md-6" style={{ padding: "20px" }}>
                <img src={Artificial} alt="Artificial" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Cricket;
