import React from "react";
import Maple from "../assets/img/maplewood.jpeg";
import SquashMW from "../assets/img/Squash.jpg";

function MapleWood() {
  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <img src={Maple} alt="Maple" />
          </div>
          <div className="col-md-12" style={{ padding: "20px" }}>
            <h1>MapleWood</h1>
            <p>
              At Dribble we use only the highest quality, WSF certified, maple
              wood flooring designed to seamlessly fit your chosen area. All the
              products we use provide the user with exceptional shock
              absorption, ball response and resilience and includes the perfect
              surface tension in order to cope with the world’s fastest paced
              sport.
            </p>
            <p>
              Additionally we provide a constant light color throughout the
              court so that the user can easily sight the ball. We also have the
              skilled, expertise in order to construct your squash court from
              the ground, up, including supplying all necessary equipment like
              rackets, balls.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <img src={SquashMW} alt="maplewood squash" />
          </div>
        </div>
        <div className="row">
          <div className="col-md-12" style={{ padding: "20px" }}>
            <h1>ADVANTAGES</h1>
            <ul>
              <li>Extremely resistant to wear and tear</li>
              <li>Easy to maintain and assemble</li>
              <li>For both indoor and outdoor use</li>
              <li>
                Available in multiple sizes and specifications in order to best
                fit your budget
              </li>
              <li>Slip resistant and enhances playing characteristics</li>
              <li>5 year warranty</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default MapleWood;
