import React from "react";
import { bannerImg1, bannerImg2 } from "../image-imports";

function Banner() {
  return (
    <div className="banner-area bg-gray transparent-nav default bottom-large">
      <div
        id="bootcarousel"
        className="carousel text-light slide carousel-fade animate_text"
        data-ride="carousel"
      >
        {/* <!-- Indicators for slides --> */}
        <div className="carousel-indicator">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <ol className="carousel-indicators">
                  <li
                    data-target="#bootcarousel"
                    data-slide-to="0"
                    className="active"
                  ></li>
                  <li data-target="#bootcarousel" data-slide-to="1"></li>
                </ol>
              </div>
            </div>
          </div>
        </div>

        {/* <!-- Wrapper for slides --> */}
        <div className="carousel-inner carousel-zoom">
          <div className="carousel-item active">
            <div
              className="slider-thumb bg-cover"
              style={{
                backgroundImage: `url(${bannerImg1})`,
              }}
            ></div>
            <div className="box-table shadow dark">
              <div className="box-cell">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-9">
                      <div className="content">
                        <h2 data-animation="animated fadeInRight">
                          We Pioneer <strong>You Play</strong>
                        </h2>
                        <p data-animation="animated slideInLeft">
                          Dribble Inc’s expertise in operation and management
                          arises from its experience in managing day-to-day
                          functioning at multiple sporting facilities.
                        </p>
                        <a
                          data-animation="animated fadeInUp"
                          className="btn btn-md btn-gradient"
                          href="/services"
                        >
                          Discover More
                        </a>
                        {/* <a
                          data-animation="animated fadeInUp"
                          className="btn btn-md btn-light border"
                          href="#"
                        >
                          View Courses
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="carousel-item">
            <div
              className="slider-thumb bg-cover"
              style={{
                backgroundImage: `url(${bannerImg2})`,
              }}
            ></div>
            <div className="box-table shadow dark">
              <div className="box-cell">
                <div className="container">
                  <div className="row">
                    <div className="col-lg-9">
                      <div className="content">
                        <h2 data-animation="animated fadeInRight">
                          We Pioneer <strong>You Play</strong>
                        </h2>
                        <p data-animation="animated slideInLeft">
                          Dribble Inc’s expertise in operation and management
                          arises from its experience in managing day-to-day
                          functioning at multiple sporting facilities.
                        </p>
                        <a
                          data-animation="animated fadeInUp"
                          className="btn btn-md btn-gradient"
                          href="/services"
                        >
                          Discover More
                        </a>
                        {/* <a
                          data-animation="animated fadeInUp"
                          className="btn btn-md btn-light border"
                          href="#"
                        >
                          View Courses
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <!-- End Wrapper for slides --> */}

        {/* <!-- Left and right controls --> */}
        <a
          className="left carousel-control light"
          href="#bootcarousel"
          data-slide="prev"
        >
          <i className="ti-angle-left"></i>
          <span className="sr-only">Previous</span>
        </a>
        <a
          className="right carousel-control light"
          href="#bootcarousel"
          data-slide="next"
        >
          <i className="ti-angle-right"></i>
          <span className="sr-only">Next</span>
        </a>
      </div>
    </div>
  );
}

export default Banner;
